
.top-banner{box-shadow: 0 2px 2px 0 rgba(0,0,0,0.05);background-color:white;width:100vw; display:flex;align-items:center;padding:.5rem 5rem; box-sizing: border-box;justify-content: space-between}
.top{min-height:16vh;border-bottom:1px solid #EEEEEE}
.logo h2{color:black;font-weight:700;text-align:center;margin:0;}
.top-banner a{color:black;font-weight:400}
.logo{display:flex;align-items:center;height:100%;}
.logo img{width:28px;height:28px;padding-right:.5rem}
.nav{height:28px;margin-left:2rem;display: flex;align-items: center;}
.nav-box{display:flex;}
.nav:hover{opacity:.8}
.main-contain{
  display:flex;
  width:100%;
  max-height:84vh;
  box-sizing: border-box;
  background-color: white;
}
.disclaimer{color:#9e9e9e;font-style:italic;padding:0;margin:0;text-overflow: ellipsis;white-space: nowrap;padding-left:3rem;}
.building-contain{width:33%;}
a{text-decoration: none}
.data-contain{
  width:35%;
  box-sizing: border-box;
  display:flex;
  align-items: center;
  flex-direction: column;
  overflow-y:auto;
  border-right:1px solid #EEEEEE;
  scrollbar-color: #E0E0E0 #FAFAFA;
  scrollbar-width: thin;
  overflow:auto;
}

.floor-plan-contain{display:flex;flex-direction: column}

.data-contain::-webkit-scrollbar-thumb{background:#E0E0E0;border-radius:500px;}
.data-contain::-webkit-scrollbar{width:5px;}
.data-contain::-webkit-scrollbar-track {background-color: #FAFAFA;}
.control-contain{width:100%; box-sizing: border-box;min-height:10vh}
.control{
  padding: 1rem 5rem;
  align-items:center;
  display:flex;
}
h1, h2{
  margin:0;
}
h2{
  color:#9e9e9e;
  font-weight:300;
}
.reset{color:white;display:flex;align-items:center;justify-content:center;cursor:pointer;
  height:36px;border:1px solid #D32F2F; color:#D32F2F;padding:1rem 2rem;
  box-sizing:border-box;border-radius:20px;transition:background-color .15s ease-in-out, color .15s ease-in-out;}
.reset:hover{
  background-color:#D32F2F;color:white;
}
.filter-contain{height:3em; border-bottom:1px solid #EEEEEE;width:100%;}
.building-list{width:100%;height:100vh;box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);}
.building-card{
  width: 100%;
  border-bottom:1px solid #EEEEEE;
  cursor:pointer;
  box-sizing: border-box;
  line-height:1.5;
  transition:background-color .15s ease-in-out;
}
.building-card:hover{background-color:#FAFAFA;}
.building-card h1{font-weight:500;font-size:1em;white-space:nowrap}
.building-card h2{font-size:1em;white-space:nowrap}
.building-card p{color:#D32F2F; padding: 0 .25rem;border-radius:2px;font-style:italic;white-space: nowrap;}
.card-over{display:flex; justify-content:space-between; align-items:center; box-sizing:border-box; padding:1rem 5rem; width: 100%; height:auto;}

.disabled{
  width: 100%;
  background-size: cover;
  border-bottom:1px solid #EEEEEE;
  background-color:#FAFAFA;
  box-sizing: border-box;
  display:flex;
  flex-direction: column;
  justify-content:center;
  line-height:1.5;
}
.disabled h1{color:grey;font-size:1em;font-weight:500}
.disabled h2{font-size:1em;}
.disabled p{color: grey;font-style:italic;}


.building-info-box{
  height:15em;
  box-sizing: border-box;
}
.building-info-box h1{font-weight:600;}

.room-card{
  padding:1rem 2rem;
  box-sizing: border-box;
  width:100%;
  background-color:white;
  border-bottom:1px solid #EEEEEE;
}
.room-card h1{margin:0;font-weight: 600;font-size:1em; white-space: nowrap;}
.room-card label, .room-card p{padding-right:1rem;font-size:1em;margin:0;}
.room-card label{color:#9e9e9e}
.room-card-grid{
  width:100%;
  display:grid;
  padding:.5rem 0;
  grid-row-gap:.5rem;
  grid-template-columns:1fr 1fr 1fr 1fr;
}
.room-title-box{display:flex;padding:.25rem 0;margin:0;width:25%;justify-content: space-between;align-items:center}
.room-title-box h1 {color:#D32F2F;padding-right:.5rem}
.room-title-box h2{color:#757575;font-size:1em;font-weight:500;cursor:default}
.suite{display:flex;align-items:center;font-size:1em;font-weight:bold;margin-right:.5rem;height:14px;width:14px;cursor:default}
.roomList{width:100%;position:relative;}
.none-selected{
  padding:4rem 2rem;
  text-align:center;
  font-weight:500;
}

button{background-color:white;border:none;margin:none;text-align:left;}
.floor-plan-link{padding:0; color:#D32F2F;padding-right:1rem;cursor:pointer;font-size:1em;padding-top:.25rem}

.building-info{box-sizing:border-box;width:100%;}
.image-wrap{cursor:pointer;}
.image-wrap img{
  z-index:0;
  width:100%;
  height:15em;
  object-fit: cover;
  filter:brightness(1.25)
}
.building-info h1{font-weight:500;}
.building-info h2{padding-bottom:1rem; color:#9e9e9e}
.info-wrap div{padding:0 0;;line-height:1.5}
.info-wrap{padding: 1rem 2rem;width:100%;box-sizing:border-box}
.info-wrap label{color:#9e9e9e;font-size:1em}
.info-wrap p{margin:0;font-size:1em}
.info-wrap h1{font-size:2em}
.info-wrap h2{font-size:1.5em}
.floor-plans{display:grid;grid-template-columns: 1fr 1fr 1fr;grid-gap:1rem;margin:2rem 0;width:75%;}
.form-control{width:150px; height:15px; right:40px;}
.building-attributes div{padding:.5rem 0}

.overlay-container{width:100%;height:100%;position:fixed;background-color:rgba(0,0,0,.75);display:flex;flex-direction:column; justify-content:center;align-items:center;z-index:1000}
.overlay-container h2{color:white;font-weight:500;padding-top:.5rem;}
iframe, .overlay-container img{width:50vw; height:66vh}

.loader-contain{width:100%; height:100vh; display:flex; justify-content: center;align-items:center;position:fixed;flex-direction:column;z-index:1000;background-color:white}
.loader-contain p{color:#D32F2F;font-style: italic;margin:.5rem;font-size:1.5em}
.loader-contain div{display:flex;box-sizing:border-box;}
.loader-logo{flex-direction: column;}
.loader-logo h2{opacity:.66;font-weight:300;margin:0;margin-left:auto;}
.loader-contain img{padding-right:1rem;width:48px;height:48px;}
.loader-contain h1{font-size:3em;margin:0;padding:0}
.floor-plan-icon{width:12px;height:12px;cursor:pointer}
.floor-plan-icon-contain{display:flex;align-items:center;padding-right:.5rem}

.building-title-div h2{padding:0;font-weight:600;
  color:  #4FC3F7;padding-left:.5rem;}
.building-title-div{display:flex;align-items:center}
.building-title-div div{height:100%;}
.maintenace-box{width:100%;height:100vh;display:flex;justify-content: center;align-items:center;text-align:center;}
.maintenace-box h1{font-size:3em}
.maintenace-box h2{font-weight:400}
.maintenace-logo{display:flex;align-items: center;padding-bottom:1rem}
.maintenace-logo img {padding-right:.5rem;width:48px;height:48px;}

.first-time-message{
  width:45%;
  background-color:white;
  border-radius:10px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
  padding:3rem 3rem;
  box-sizing: border-box;
}
.first-time-message div{padding-top:.5rem}
.first-time-message h1{
  font-weight:500;
}
.first-time-message p{
  color:#909090;line-height: 1.5;margin:.5rem 0;
}
.first-time-message button{
  float:right;
  border:1px solid #D32F2F;
  color:#D32F2F;
  padding:.5rem 1rem;
  font-size:1em;
  cursor:pointer;
  margin-top:1rem;
}
.first-time-message button:active{
  background-color: #D32F2F;
  color:white;
}
.bold{font-weight:500;color:#D32F2F !important}
.intro-message-wrap{display:flex;align-items:center;  padding-bottom:1rem; border-bottom:1px solid #EEEEEE;}
.intro-message-wrap img{width:36px; height:36px; padding-right:1rem}

/**styles for mobile devices--**/
 .mobile--page-title-box{width:100%;border-top:1px solid #EEEEEE;border-bottom:1px solid #EEEEEE; padding:1rem 2rem;
display:flex;justify-content: space-between;box-sizing:border-box;align-items: center;position:sticky;
position: -webkit-sticky;top:0;background-color: white;z-index:500; box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);}
 .mobile--page-title-box h1{font-size:1.5em;font-weight:500;line-height: 1;}
 .mobile--page-title-box div{display:flex;align-items: center;}
 .back-img{padding-right:1rem;height:24px;width:24px}
 .filter-img{width:24px;height:24px;}
 .mobile--room-title-box{width:100%;display:flex;align-items:center;padding:1rem 2rem;border-bottom:1px solid #EEEEEE;
box-sizing:border-box;justify-content: space-between;z-index:-200}
 .mobile--room-title-box h2{font-weight:300;color:black}
 .mobile--filter-box{height:110vh;position:fixed;width:100vw;background-color: white;
  box-sizing:border-box;padding:1rem 2rem;display:block;z-index:1000;top:0;left:0;right:0;bottom:0;overflow-y: scroll;
  -webkit-backface-visibility: hidden;backface-visibility: hidden;}
  .mobile--filter-title-box{width:100%;display:flex;align-items: center;justify-content: space-between;padding-bottom:1rem}
  .mobile--filter-box img{width:16px;height:16px}
  .mobile--apply-filter{width:128px;color:#424242;border:1px solid #424242;border-radius:25px;
    transition:background-color .15s ease-in-out, color .15s ease-in-out;text-align: center;padding:.75rem;margin:1rem 0}
  .mobile--reset-filter{width:128px; color:#D32F2F;border:1px solid #D32F2F; border-radius:25px;text-align:center;
     padding:.75rem;transition:background-color .15s ease-in-out, color .15s ease-in-out;}
  .mobile--apply-filter:active{background-color: #424242;color:white;}
  .mobile--reset-filter:active{background-color:#D32F2F;color:white}
  .exit-filter{font-size:2em;color:grey;}
  .mobile--footer{
    width:100vw;
    display:flex;
    justify-content: space-between;
    padding:.5rem 2rem;
    box-sizing:border-box;
    align-items: center;
    box-shadow: inset 0 0 8px #E0E0E0;
    background-color: #FAFAFA;
  }
  .mobile--footer-logo{display:flex;align-items: center;}
  .mobile--footer-logo img{width:22px;height:22px;}
  .mobile--footer-logo h1{font-size:1.25em;padding-left:.5rem}
 @media screen and (max-device-width: 480px) and (orientation: portrait){
  .first-time-message{
    width:90%;
    background-color:white;
    border-radius:10px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
    padding:2rem 2rem;
    box-sizing: border-box;
  }
  .first-time-message img{width:38px !important;height:38px !important;}
  .data-contain{
    width:100vw;
  }
  .nav-box{width:40%;justify-content: space-between;flex-direction: column;}
  .nav {margin:0;justify-content: flex-end;}
  .nav a{color:black;font-weight:400;text-decoration: underline;opacity:.8}
  .building-title-div h2{padding-left:1rem}
  .main-contain{flex-direction:column;width:100vw;}
  .control{padding:2rem;display:grid;grid-template-columns: 1fr 1fr;height:auto;grid-gap:1rem}
  .data-contain{width:100%;}
  .reset{display:none}
  .building-card div, .disabled div{padding:1rem;}
  .building-card:hover{background-color:white;}
  .building-attributes{display:grid;grid-template-columns: 1fr 1fr 1fr;grid-gap:1rem 2rem}
  .address-link {grid-column:1/4}
  .info-wrap{border-bottom:1px solid #EEEEEE;padding:2rem 2rem}
  .info-wrap h1, .info-wrap h2{font-size:1.5em}
  .info-wrap div{padding:0}
  .button-wrap{width:50px;height:24px;}
  iframe, .overlay-container img{width:90vw; height:auto;}
  
} 
